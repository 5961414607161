<template>
	<mainPop top='5vh' popWidth=1200px @cancel='dialogVisible = false' @confirm="submitForm" :openhint=dialogVisible
		:titleText=dialogVisibleTitle v-model:dialogloading="state.loading">
		<template v-slot:el-popBody>
			<div style="height:700px">
				<el-form :rules="rules" class="mt-31 w-420 mb-45" label-position=top label-width="80px" ref="ruleFormRef"
					:model="ruleForm">
					<!-- <el-form-item label="签名名称：" prop="signatureName" v-if="selectName == '标准签名'">
						<el-input placeholder="请输入签名名称" v-model="ruleForm.signatureName"></el-input>
					</el-form-item> -->
					<div class="dp-f cu-p" v-if="dialogVisibleTitle!='修改签名'">
						<div class="group-item first" @click="()=>{ selectName = '标准签名',clearInterval()}" :class="{ isActive: selectName == '标准签名' }">
							标准签名
						</div>
						<div class="group-item middle" @click="()=>{selectName = '手绘签名',getWXsgin()}"
							:class="{ isActive: selectName == '手绘签名', middleBr: selectName == '上传图片', middleBl: selectName == '标准签名' }">
							手绘签名
						</div>
						<!-- <div class="group-item last" @click="selectName = '上传图片'" :class="{ isActive: selectName == '上传图片' }">
						上传图片
					</div> -->
					</div>
					<div class="mt-35 dp-f" v-if="selectName == '标准签名'">
						<div class="w-220 ta-c">
							<div class="w-220 h-220 signBox dp-fc" v-loading="state.viewloading">
								<img class="w-180 h-180" v-if="state.viewdata" :src="state.viewdata.fileUrl">
							</div>
							<p class="mt-28">签名预览</p>
						</div>
						<div class="ml-37">
							<p @click="test">选择签名样式</p>
							<signTemp ref="signTempRef" class="mb-30" @handlClick="handlClick" :signStyle='state.signStyle'
								:signName='ruleForm.signName' />
							<el-form-item label="签名内容:" prop="signName">
								<el-input v-model="ruleForm.signName" class="h-46 w-420" maxlength="4" placeholder="请输入签名内容"
									@change="getpreview()"></el-input>
							</el-form-item>
							<div class="mt-30">
								<div class="mb-14">签名颜色</div>
								<div class="dp-f">
									<div class="mr r50 cu-p mr-10" @click="state.signColorIndex = ind, getpreview()"
										:class="{ bc000: state.signColorIndex == ind }" v-for="(el, ind) in state.signColors" :key="ind">
										<div class="r50 w100 h100" :style="{ 'background-color': el }"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="dp-f mt-25" v-else-if="selectName == '手绘签名'">
						<div class="b-1 br-9 w-420 h-302 pt-32 ta-c">
							<p class="c-999 mb-28">请使用微信“扫一扫”后，绘制个人签名</p>
							<div class="b-1 br-9 w-155 h-155" style="margin:0 auto;">
								<img :src="state.wxcode" alt="" class="w-150">
							</div>
							<div class="mt-14 dp-f ai-c jc-c">
								<el-image class="w-16 h-16 mr-5" :src="require('@/assets/img/sign/refresh.png')" />
								<span style="color:#1890FF">刷新</span>
							</div>
						</div>
					</div>
					<dragbox ref="dragboxRef" class="mt-25" v-else-if="selectName == '上传图片'"></dragbox>
				</el-form>
			</div>
		</template>
	</mainPop>
</template>

<script setup>
import { ref, unref, reactive, defineEmits, nextTick,computed,onUnmounted } from "vue";
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm.js"
import { uploadFile } from "@/utils/server/upload.js"
import { httpToken } from "@/utils/request";
import { useStore } from "vuex";
import qs from "qs";
import mainPop from "@/components/dialog/mainPop.vue";
import signTemp from "./signTemp.vue";
import dragbox from "./dragbox.vue"
const dialogVisible = ref(false);//弹框显示
const dialogVisibleTitle = ref('新增签名')
const store = useStore();
const dragboxRef = ref()
const ruleFormRef = ref()//表单ref
const signTempRef=ref()
const signatureId=ref(false)
const state = reactive({
	signStyle: 0,//当前选择签名样式下标
	signData: false,//当前选择签名样式数据
	signColorIndex: 0,//签名颜色下标
	signColors: ['#000000', '#FF0000', '#1890FF'],//签名颜色
	loading: false,
	viewloading: false,
	viewdata: false,//预览数据
	wxcode:'',
	vxlength:computed(() => store.getters.getsignLength),
	timmer:null
});

const ruleForm = reactive({
	// signatureName: "",//签名名称
	signName: "",//签名内容
});
const selectName = ref("标准签名");
const rules = reactive({
	// signatureName: [
	// 	{ required: true, message: "请输入签名名称", trigger: "blur" },
	// ],
	signName: [
		{ required: true, message: "请输入签名内容", trigger: "blur" },
		{ min: 2, max: 4, message: '签名内容长度为2-4位', trigger: 'blur' }
	],
});
// 获取预览数据
const getpreview = () => {
	state.viewdata = false
	state.viewloading = true
	httpToken({
		method: "post",
		url: '/admin/personalsignature/makePreviewPersonal',
		data: {
			type: state.signData.type,
			colorType: state.signColorIndex,
			sealContent: ruleForm.signName,
		}
	}).then((res) => {
		state.viewdata = res.data
		state.viewloading = false
		console.log('获取预览数据', res)
	}).catch(() => {
		state.viewloading = false
	})
}
const handlClick = (ind, el) => {
	state.signStyle = ind
	state.signData = el
	getpreview()
}
// 上传图片 保存数据
const getWXsgin=()=>{
// 
	httpToken({
		method: "post",
		url: '/sys-file/sys-file/getHandDrawn',
	}).then(res=>{
			state.wxcode=res.data
			// admin/personalsignature/all
	state.timmer=setInterval(()=>{
		httpToken({
		method: "post",
		url: '/admin/personalsignature/all',
	}).then(res=>{
		if(state.vxlength!=res.data.length){
		dialogVisible.value=false			
 		window.clearInterval(state.timmer); //清除定时器
        state.timmer = null;
		selectName.value = '标准签名'
			emit('Emit')
		}
	})
	},1000)
	})
}
const clearInterval=()=>{
			window.clearInterval(state.timmer); //清除定时器
        state.timmer = null;
}

onUnmounted(() => {
  window.clearInterval(state.timmer); //清除定时器
  state.timmer = null;

})
const saveimgData = () => {
	if (!unref(dragboxRef).getFile()) {
		handleMessage('请先上传文件')
		return
	}
	state.loading = true
	uploadFile(unref(dragboxRef).getFile().file, "personalsignature", undefined, '/sys-file/addOneForBackground').then((res) => {
		// console.log('上传图片接口',res.data.id)
		httpToken({
			method: "post",
			url: '/admin/personalsignature/add',
			data: {
				// signatureName: ruleForm.signatureName,
				fileId: res.data.id,
				addMethod: 2
			}
		}).then((res2) => {
			state.loading = false
			dialogVisible.value = false
			emit('Emit', res2)
		}).catch(err2 => {
			state.loading = false
		})
	}).catch(err => {
		state.loading = false
	})
}
// 标准签名保存数据
const saveData = (url = '/admin/personalsignature/add', data) => {
	state.loading = true
	httpToken({
		method: "post",
		url: url,
		data: data
	}).then((res2) => {
		state.loading = false
		dialogVisible.value = false
		emit('Emit', res2)
	}).catch(err2 => {
		state.loading = false
	})
}
// 表单提交 触发整个表单校验
const submitForm = async (el) => {
	const form = unref(ruleFormRef)
	if (!form) return
	await form.validate((valid) => {
		if (valid) {
			if (selectName.value == '标准签名') {
				if (!state.viewdata) {
					handleMessage('请等待标准签名生成')
					return
				}
				console.log('state.viewdata', state.viewdata)
				let data = {
					signatureName: ruleForm.signatureName,
					fileId: state.viewdata.id,
					type: state.signData.type,
					colorType: state.signColorIndex,
					sealContent: ruleForm.signName,
					addMethod: 0
				}
				if (dialogVisibleTitle.value == '新增签名') {
					saveData(undefined, data)
				} else {
					data.signatureId=signatureId.value
					saveData('/admin/personalsignature/update', data)
				}
			} else if (selectName.value == '上传图片') {
				saveimgData()
			}
		} else {
			console.log('error')
		}
	})
}
const show = () => {
	if (JSON.parse(window.sessionStorage.user).user_info.isCertification == 0) {
		handleMessage('请先完成个人实名')
		return
	}
	objdata_ret(ruleForm)
	state.signColorIndex = 0
	dialogVisibleTitle.value = '新增签名'
	dialogVisible.value = true;
	ruleForm.signName = JSON.parse(window.sessionStorage.user).user_info.chnname
	nextTick(() => {
		getpreview()
		// unref(dragboxRef).resetFile()
	})
}
const editshow = (data) => {
	if (JSON.parse(window.sessionStorage.user).user_info.isCertification == 0) {
		handleMessage('请先完成个人实名')
		return
	}
	state.signColorIndex = 0
	objdata_ret(ruleForm)
	ruleForm.signName = JSON.parse(window.sessionStorage.user).user_info.chnname
	signatureId.value=data.signatureId
	if(data.addMethod==2){
		selectName.value='上传图片'
		ruleForm.signatureName=data.signatureName
	}else if(data.addMethod==0){
		selectName.value='标准签名'
		ruleForm.signatureName=data.signatureName
		state.signColorIndex=data.colorType
		ruleForm.signName=data.sealContent
		// console.log('unref(signTempRef).getStyleIndex(state.signData.type)',unref(signTempRef).getStyleIndex(state.signData.type))
	}
	dialogVisibleTitle.value = '修改签名'
	dialogVisible.value = true;
	nextTick(() => {
		// unref(dragboxRef).resetFile()
		if(data.addMethod==0){
			state.signStyle=unref(signTempRef).getStyleIndex(state.signData.type)
			getpreview()
		}
	})
}
const emit = defineEmits(['Emit'])
defineExpose({
	show, editshow
});
</script>

<style lang="scss" scoped>
.group-item {
	border: 1px solid #dedede;
	padding: 8px 18px;
}

.first {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	border-right: none;
}

.last {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	border-left: none;
}

.isActive {
	border-color: var(--active-theme);
}

.middleBr {
	border-right-color: var(--active-theme);
}

.middleBl {
	border-left-color: var(--active-theme);
}

.signBox {
	border: 1px dashed #c7c7c7;
}

.w-420 {
	width: 420px;
}

.mr {
	width: 28px;
	height: 28px;
	padding: 2px;

	.bg-189 {
		background-color: #1890ff;
	}
}

.r50 {
	border-radius: 50%;
}

.bc000 {
	border: 1px solid #333;
}

.rl {
	writing-mode: vertical-rl;
}

.lr {
	writing-mode: vertical-lr;
}

.p-13 {
	padding: 13px;
}

.b-000 {
	border: 1px solid #333;
}
</style>