<template>
  <el-dialog :custom-class="popClass" :top="top" :show-close=false :title="titleText" :before-close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false" v-model="visible" :width=popWidth>
    <template #title>
      <div class="titledialog">
        <div class="headerleft">
          <el-image class="QRicon" v-if="leftIcon" :src="leftIcon" />
          <p>{{titleText}}</p>
        </div>
      </div>
    </template>
    <div class="popBody">
      <slot name="el-popBody"></slot>
    </div>
    <slot name="el-footer"></slot>
    <div class="footer">
      <oabutton title='取消' @buttonclick='handleClose' class="mr-10" width=110 height=52 CSStype=3  ></oabutton>
      <oabutton title='保存' @buttonclick='confirm' width=110 height=52 CSStype=2  v-model:loading="dialogloading"></oabutton>
    </div>
  </el-dialog>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
export default defineComponent({
  props: {
    //loading 显示
    loading: {
      type: Boolean,
      default: false
    },
    top:{
      type:String,
      default :'10vh'
    },
    popWidth: {
      //弹窗宽度
      type: String,
      default: "1090px",
    },
    openhint: {
      //弹窗显示隐藏
      type: Boolean,
      default: false,
    },
    titleText: {
      //标题
      type: String,
    },
    leftIcon: {
      type: String,
    },
    popClass: {
      type: String,
      default:'mainPopClass'
    },
    dialogloading:{
      type:Boolean,
      default:false
    }
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.openhint;
    });
    const confirm = () => {
      ctx.emit("confirm");
    };
    const handleClose = () => {
      ctx.emit("cancel");
    };
    return {
      visible,
      confirm,
      handleClose,
    };
  },
});
</script>

<style lang="scss">
@import "@/styles/general/element/dialogStyle.scss";
</style>
