<template>
	<el-upload ref="uploadRef" class="upload-demo" drag action="#" :limit="1" :accept='import_img_accept'
		:http-request="ImgHttpImport" list-type="picture-card" multiple>
		<div class="dragbox dp-fc ac-c fw-w" v-show="!state.nowFile">
			<img src="@/assets/img/sign/bigup.png" class="w-64 h-64 mb-28" />
			<div class="w100 dp-f jc-c">
				请将图片拖拽至此，或<img src="@/assets/img/general/upload.png" class="w-16 h-16 ml-6 mr-3 mt-2 cu-p" /><span
					class="cu-p">点击上传</span>
			</div>
		</div>
		<template #file="{ file }">
			<div class="dragbox dp-fc imgdragbox" v-show="state.nowFile">
				<img class="fileImg" :src="file.url" alt="" />
				<div class="delbox dp-fc" @click="resetFile()">
					<img class="w-28 h-28" src="@/assets/img/general/delete.png">
				</div>
			</div>
		</template>
	</el-upload>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from "vue";
import { handleMessage } from "@/utils/server/confirm";
import { import_img_accept } from "@/utils/base/accept"
import { getfileTypeName } from "@/utils/server/file.js"
const state = reactive({
	nowFile: false,
});
const uploadRef = ref()//上传组件ref
// 图片上传方法
const ImgHttpImport = ((rawFile) => {
	console.log('rawFile', rawFile, rawFile.file.size)
	let fileTypeName = getfileTypeName(rawFile.file.name)
	let imgtype = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'bmp', 'BMP']
	console.log('fileTypeName', fileTypeName)
	if (rawFile.size / 1024 / 1024 > 5) {
		handleMessage('图片大小超过5MB')
		resetFile()
	} else if (imgtype.includes(fileTypeName)) {
		state.nowFile = rawFile
	} else {
		handleMessage('请上传正确图片格式!')
		resetFile()
	}
})
const resetFile = (() => {
	state.nowFile = false
	nextTick(() => {
		unref(uploadRef).clearFiles()
	})
})
const getFile = (() => {
	return state.nowFile
})
defineExpose({
	getFile,resetFile
});
</script>
<style lang="scss" scoped >
::v-deep .el-upload-dragger {
	border: none !important;
	padding: 0 !important;
}

::v-deep .el-upload--picture-card {
	border: none !important;
}

::v-deep .el-upload--picture-card {
	--el-upload-picture-card-size: 100%;
}

::v-deep .el-upload-list--picture-card {
	--el-upload-list-picture-card-size: 100%;
}

.dragbox {
	width: 420px;
	height: 246px;
	border-radius: 6px;
	border: 1px solid #CCCCCC;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;

	span {
		color: #1890FF;
	}

	.fileImg {
		max-width: calc(100% - 30px);
		max-height: calc(100% - 30px);
	}
}

.imgdragbox {
	// padding: 15px;
	position: relative;

	.delbox {
		display: none;
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
		background: rgba(0, 0, 0, 0.4);
	}
}

.imgdragbox:hover {
	.delbox {
		display: flex;
	}
}
</style>