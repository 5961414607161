<template>
  <div class="dp-f mt-14 fs-25">
    <div v-for="(el, ind) in state.Styles" :key="ind" class="item" :class="props.signStyle == ind ? 'itemac' : ''"
      @click="handlClick(ind,el)">
      <div class="imgbox">
        <img :src="el.url">
        <el-image class="checkImg w-24 h-24" :src="require('@/assets/img/sign/check.png')"></el-image>
      </div>
      <div class="name">{{el.fileName}}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
const emit = defineEmits(['handlClick'])
const props = defineProps({
  signName: {
    type: String,
  },
  signStyle: {
    type: Number,
    default: 0
  }
})
const state = reactive({
  Styles: [],
});
// 获取个人签名样式
const getStyle = () => {
  httpToken({
    method: "post",
    url: '/admin/personalsignature/getStyle',
  }).then((res) => {
    state.Styles=res.data
    emit('handlClick', 0,res.data[0])
  })
}
getStyle()
const getStyleIndex=(type)=>{
  for(let i=0;i<state.Styles.length;i++){
    if(state.Styles[i].type==type){
      return i
    }
  }
}
const handlClick = (val,el) => {
  emit('handlClick', val,el)
}
defineExpose({
	getStyleIndex
});
</script>

<style lang="scss" scoped>
.item {
  margin-right: 20px;

  .imgbox {
    width: 110px;
    height: 110px;
    border: 1px solid #E6E6E6;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    .checkImg{
      display: none;
      position: absolute;
      bottom: 0;
      right: 0px;
    }
  }
  
  .name {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}

.itemac {
  .imgbox {
    border: 1px solid #EAC473;
    .checkImg{
      display: block;
    }
  }
}</style>